/*
 * @Author: 张博洋
 * @Date: 2021-07-22 11:22:30
 * @LastEditTime: 2021-10-22 14:42:07
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/mixin/IndexBar.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import { IndexBar, IndexAnchor } from 'vant';

export default {
  components: (() => {
    let obj = {};
    obj[IndexBar.name] = IndexBar;
    obj[IndexAnchor.name] = IndexAnchor;
    return obj;
  })(),
}
