<!--
 * @Author: 张博洋
 * @Date: 2021-10-22 14:38:26
 * @LastEditTime: 2021-12-28 17:24:40
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/city/list.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="city">
    <van-index-bar :index-list="indexList"
                   :sticky="false">
      <div v-for="(item,index) in indexList"
           :key="index">
        <van-index-anchor :index="item">{{item}}</van-index-anchor>
        <van-cell title="文本" />
        <van-cell title="文本" />
        <van-cell title="文本" />
      </div>

    </van-index-bar>
  </div>

</template>


<script>
import IndexBar from '@/mixin/IndexBar'
export default {
  mixins: [IndexBar],
  data() {
    return {
      indexList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    }
  },
}
</script>

<style lang="scss" scoped>
.city {
  overflow: auto;
}
</style>